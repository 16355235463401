import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import ContactMe from '../components/OtherPages/ContactMe';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { themeColor } from '../components/SetColor';
import Loading from '../components/OtherPages/Loading';
import { SetLoading } from '../components/SetLoading';

const ContactMePage = () => {
  const loading = SetLoading(300);
  return (
    <>
      { loading ? (
        <Loading tColor={themeColor} loading={loading}/>
      ) : (
        <HelmetProvider>
          <Helmet><title>mm-code | Contact Me</title></Helmet>
          <ScrollToTop />
          <ContactMe tColor={themeColor} />
        </HelmetProvider>
      )};
    </>
  );
};

export default ContactMePage;

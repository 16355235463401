import styled from "styled-components";
import { Link } from "react-router-dom";

export const OtherPagesContainer = styled.div`
  height: ${({ screenHeight }) => (screenHeight)};
  position: fixed;
  display: grid;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: ${({ lightBg }) => (lightBg ? 
    'linear-gradient(108deg, rgba(249, 449, 249, 1) 0%, rgba(255, 255, 255, 1) 100%)' : 'linear-gradient(108deg, rgba(0, 0, 0, 1) 0%, rgba(31, 36, 36, 1) 100%)'
  )};
`;

export const OtherPagesWrapper = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  grid-row-gap: 10px;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 100%;
  max-width: 1100px;
`;

export const LinkContainer = styled(Link)`
  cursor: pointer;
  width: 200px;
  height: 120px;
  margin-top: 5px;
`;

export const Logo = styled.img`
  height: 100px;
  width: 180px;
`;

export const OtherPagesRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  padding-bottom: 100px;
  grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

  @media screen and (max-width: 800px) {
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  };
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;

  @media screen and (max-width: 1000px) {
    margin-bottom: 10px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
  };
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 1000px) {
    margin-bottom: 10px;
    padding: 0 10px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
    padding: 0 8px;
  };
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 10px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 30px;
  line-height: 1.3;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#010606')};

  @media screen and (max-width: 1000px) {
    margin-bottom: 18px;
    font-size: 22px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 14px;
    font-size: 18px;
  };
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#010606' : '#f7f8fa')};

  @media screen and (max-width: 1000px) {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 18px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 14px;
  };
`;

export const TimePeriod = styled.p`
  max-width: 440px;
  margin-bottom: 6px;
  font-size: 25px;
  line-height: 24px;
  color: ${({ tColor }) => (tColor.primaryColor)};

  @media screen and (max-width: 1000px) {
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 18px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 14px;
  };
`;

export const Data = styled.p`
  max-width: 440px;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 24px;
  color: #010606;

  @media screen and (max-width: 1000px) {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
  };
  @media screen and (max-width: 480px) {
    margin-bottom: 2px;
    font-size: 10px;
    line-height: 14px;
  };
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;

  @media screen and (max-width: 800px) {
    max-width: 400px;
  };
  @media screen and (max-width: 480px) {
    max-width: 300px;
  };
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
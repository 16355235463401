import styled from "styled-components";

export const SkillsContainer = styled.div`
  height: ${({ screenHeight }) => (screenHeight)};
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ tColor }) => (tColor.primaryColor)};

  @media screen and (max-height: 800px) {
    height: 800px;
  };
  @media screen and (max-width: 1000px) {
    height: 700px;
  };
  @media screen and (max-width: 800px) {
    padding: 400px 0;
    height: 1520px;
  };
  @media screen and (max-width: 480px) {
    padding: 250px 0;
    height: 1240px;
  };
  @media screen and (max-width: 380px) {
    height: 1100px;
  };
`;

export const SkillsWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  /* height: 860px; */
  display: grid;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  };
`;

export const Column = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgUp }) => (imgUp ? `'row2' 'row1'` : `'row1' 'row2'`)};

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-areas:'row2' 'row1';
  };
`;

export const Row1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: row1;
`;

export const Row2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: row2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  /* padding-bottom: 60px; */
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  };
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #010606;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

import styled from "styled-components";

export const ImgContainer = styled.div`
`;

export const ImgWrap = styled.div`
  position: absolute;
  width: ${({ w }) => (w)};
  height: 100%;
  transform: matrix(1, 0, 0, 1, ${({ trX }) => (trX)}, ${({ trY }) => (trY)})  rotate(${({ rot }) => (rot)});
  transition: transform 100ms;
  `;

export const Img = styled.img`
  width: 100%;
`;
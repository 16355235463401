import React from 'react';
// import { fromEvent } from 'rxjs'
// import { map, throttleTime } from 'rxjs/operators'
import {
  countHeight,
  countWidth
} from '../../CountDimensions';
import myCircleCyan from '../../../images/HeroSection/circle_cyan.svg';
import myCircleGreen from '../../../images/HeroSection/circle_green.svg';
import myCircleMagenta from '../../../images/HeroSection/circle_magenta.svg';
import myCircleOrange from '../../../images/HeroSection/circle_orange.svg';
import myExPink from '../../../images/HeroSection/ex_pink.svg';
import myExRed from '../../../images/HeroSection/ex_red.svg';
import myExViolet from '../../../images/HeroSection/ex_violet.svg';
import myExYellow from '../../../images/HeroSection/ex_yellow.svg';
import myLineGreen from '../../../images/HeroSection/line_green.svg';
import myLineMagenta from '../../../images/HeroSection/line_magenta.svg';
import myLinePink from '../../../images/HeroSection/line_pink.svg';
import myLineYellow from '../../../images/HeroSection/line_yellow.svg';
import mySemiCircleOrange from '../../../images/HeroSection/semiCircle_orange.svg';
import mySemiCirclePink from '../../../images/HeroSection/semiCircle_pink.svg';
import mySemiCircleViolet from '../../../images/HeroSection/semiCircle_violet.svg';
import mySemiCircleYellow from '../../../images/HeroSection/semiCircle_yellow.svg';
import mySpringCyan from '../../../images/HeroSection/spring_cyan.svg';
import mySpringGreen from '../../../images/HeroSection/spring_green.svg';
import mySpringMagenta from '../../../images/HeroSection/spring_magenta.svg';
import mySpringRed from '../../../images/HeroSection/spring_red.svg';
import myTriangleCyan from '../../../images/HeroSection/triangle_cyan.svg';
import myTriangleOrange from '../../../images/HeroSection/triangle_orange.svg';
import myTriangleRed from '../../../images/HeroSection/triangle_red.svg';
import myTriangleViolet from '../../../images/HeroSection/triangle_violet.svg';
import {
  ImgContainer,
  ImgWrap,
  Img
 } from './ShapesElements';

const Shapes = () => {
  const screenHeight = Number(countHeight().replace('px', ''));
  const screenWidth = Number(countWidth().replace('px', ''));

  // const useMousePosition = () => {
  //   const [x, setX] = useState(null)
  //   const [y, setY] = useState(null)
  
  //   useEffect(() => {
  //     const sub = fromEvent(document, 'mousemove')
  //       .pipe(
  //         throttleTime(100),
  //         map(event => [event.pageX, event.pageY])
  //       )
  //       .subscribe(([newX, newY]) => {
  //         setX(newX)
  //         setY(newY)
  //       })
  
  //     return () => {
  //       sub.unsubscribe()
  //     }
  //   }, [])
  
  //   return {
  //     mouseX: x,
  //     mouseY: y,
  //   }
  // }
  
  // const x = useMousePosition().mouseX - screenWidth/2;
  // const y = useMousePosition().mouseY - screenHeight/2;

  const x = 0 - screenWidth/2;
  const y = 0 - screenHeight/2;

  return (
    <ImgContainer >
      <ImgWrap w={'41px'} trX={0.02*x+0.45*screenWidth} trY={0.06*y+0.07*screenHeight} rot={'0deg'}>
        <Img src={myCircleCyan} alt='myCircleCyan' />
      </ImgWrap>
      <ImgWrap w={'32px'} trX={-0.05*x+0.85*screenWidth} trY={0.12*y+0.07*screenHeight} rot={'0deg'}>
        <Img src={myCircleGreen} alt='myCircleGreen' />
      </ImgWrap>
      <ImgWrap w={'30px'} trX={-0.21*x+0.45*screenWidth} trY={-0.05*y+0.09*screenHeight} rot={'0deg'}>
        <Img src={myCircleMagenta} alt='myCircleMagenta' />
      </ImgWrap>
      <ImgWrap w={'24px'} trX={0.12*x+0.85*screenWidth} trY={-0.25*y+0.87*screenHeight} rot={'0deg'}>
        <Img src={myCircleOrange} alt='myCircleOrange' />
      </ImgWrap>
      <ImgWrap w={'34px'} trX={0.17*x+0.45*screenWidth} trY={-0.04*y+0.77*screenHeight} rot={'10deg'}>
        <Img src={myExPink} alt='myExPink' />
      </ImgWrap>
      <ImgWrap w={'23px'} trX={0.06*x+0.45*screenWidth} trY={0.15*y+0.09*screenHeight} rot={'-30deg'}>
        <Img src={myExRed} alt='myExRed' />
      </ImgWrap>
      <ImgWrap w={'38px'} trX={-0.23*x+0.75*screenWidth} trY={0.04*y+0.12*screenHeight} rot={'0deg'}>
        <Img src={myExViolet} alt='myExViolet' />
      </ImgWrap>
      <ImgWrap w={'40px'} trX={-0.07*x+0.01*screenWidth} trY={-0.11*y+0.57*screenHeight} rot={'30deg'}>
        <Img src={myExYellow} alt='myExYellow' />
      </ImgWrap>
      <ImgWrap w={'32px'} trX={-0.02*x+0.45*screenWidth} trY={0.05*y+0.07*screenHeight} rot={'30deg'}>
        <Img src={myLineGreen} alt='myLineGreen' />
      </ImgWrap>
      <ImgWrap w={'45px'}  trX={-0.03*x+0.04*screenWidth} trY={0.1*y+0.83*screenHeight} rot={'0deg'}>
        <Img src={myLineMagenta} alt='myLineMagenta' />
      </ImgWrap>
      <ImgWrap w={'42px'}  trX={-0.25*x+0.55*screenWidth} trY={-0.03*y+0.014*screenHeight} rot={'70deg'}>
        <Img src={myLinePink} alt='myLinePink' />
      </ImgWrap>
      <ImgWrap w={'40px'}  trX={0.023*x+0.4*screenWidth} trY={0.012*y+0.27*screenHeight} rot={'90deg'}>
        <Img src={myLineYellow} alt='myLineYellow' />
      </ImgWrap>
      <ImgWrap w={'28px'}  trX={-0.04*x+0.2*screenWidth} trY={0.015*y+0.25*screenHeight} rot={'-18deg'}>
        <Img src={mySemiCirclePink} alt='mySemiCirclePink' />
      </ImgWrap>
      <ImgWrap w={'25px'}  trX={0.18*x+0.02*screenWidth} trY={0.2*y+0.08*screenHeight} rot={'130deg'}>
        <Img src={mySemiCircleOrange} alt='mySemiCircleOrange' />
      </ImgWrap>
      <ImgWrap w={'30px'}  trX={-0.024*x+0.8*screenWidth} trY={-0.07*y+0.7*screenHeight} rot={'-30deg'}>
        <Img src={mySemiCircleViolet} alt='mySemiCircleViolet' />
      </ImgWrap>
      <ImgWrap w={'35px'}  trX={0.09*x+0.5*screenWidth} trY={-0.23*y+0.07*screenHeight} rot={'30deg'}>
        <Img src={mySemiCircleYellow} alt='mySemiCircleYellow' />
      </ImgWrap>
      <ImgWrap w={'22px'}  trX={0.015*x+0.25*screenWidth} trY={-0.13*y+0.65*screenHeight} rot={'0deg'}>
        <Img src={mySpringCyan} alt='mySpringCyan'/>
      </ImgWrap>
      <ImgWrap w={'15px'}  trX={-0.06*x+0.2*screenWidth} trY={0.05*y+0.4*screenHeight} rot={'90deg'}>
        <Img src={mySpringGreen} alt='mySpringGreen'/>
      </ImgWrap>
      <ImgWrap w={'28px'}  trX={0.17*x+0.15*screenWidth} trY={0.14*y+0.14*screenHeight} rot={'30deg'}>
        <Img src={mySpringMagenta} alt='mySpringMagenta' />
      </ImgWrap>
      <ImgWrap w={'25px'}  trX={-0.1*x+1.1*screenWidth} trY={-0.04*y+0.3*screenHeight} rot={'-66deg'}>
        <Img src={mySpringRed} alt='mySpringRed' />
      </ImgWrap>
      <ImgWrap w={'35px'} trX={-0.02*x+0.6*screenWidth} trY={0.05*y+0.25*screenHeight} rot={'90deg'}>
        <Img src={myTriangleCyan} alt='myTriangleCyan'/>
      </ImgWrap>
      <ImgWrap w={'40px'}  trX={0.04*x+0.01*screenWidth} trY={0.26*y+0.21*screenHeight} rot={'20deg'}>
        <Img src={myTriangleOrange} alt='myTriangleOrange' />
      </ImgWrap>
      <ImgWrap w={'48px'} trX={0.12*x+0.05*screenWidth} trY={-0.07*y+0.45*screenHeight} rot={'0deg'}>
        <Img src={myTriangleRed} alt='myTriangleRed'/>
      </ImgWrap>
      <ImgWrap w={'55px'}  trX={-0.08*x+0.15*screenWidth} trY={-0.1*y+0.02*screenHeight} rot={'60deg'}>
        <Img src={myTriangleViolet} alt='myTriangleViolet' />
      </ImgWrap>
      {/* <ImgWrap> */}
      {/* <svg>
          <circle cx="70" cy="70" r="70"></circle>
      </svg> */}
      {/* </ImgWrap> */}

      {/*<div id="shapes-mask" class="is-loaded">
      <svg width="25px" height="25px" class="layer1 p1" style="transform: matrix(-1, 0, 0, -1, 15.6154, -2.7692)"">
          <use href="#triangle" class="yellow">
          </use>
      </svg> 
      <svg width="28px" height="28px" class="layer1 p2" style="transform: matrix(1, 0, 0, 1, 15.6154, -42.7692);">
          <use href="#semiCircle" class="yellow">
          </use>
      </svg>
      <svg width="25px" height="25px" class="layer2 p3" style="transform: matrix(0.70711, 0.7071, -0.7071, 0.70711, -15.6154, 42.7692);">
          <use width="25px" height="25px" href="#triangle" class="aqua">
          </use>
      </svg>
      <svg class="layer1 p4" style="transform: matrix(1, 0, 0, 1, 15.6154, -42.7692);">
          <use href="#triangle" class="pink">
          </use>
      </svg>
      <svg width="28px" height="28px" class="layer1 p5" style="transform: matrix(0.70711, 0.7071, -0.7071, 0.70711, 15.6154, -42.7692);">
          <use href="#semiCircle" class="aqua">
          </use>
      </svg>
      <svg width="36px" height="36px" class="layer2 p6" style="transform: matrix(0.70711, 0.7071, -0.7071, 0.70711, -15.6154, 42.7692);">
          <use href="#spring" class="pink">
          </use>
      </svg>
      <svg width="36px" height="36px" class="layer2 p7" style="transform: matrix(1, 0, 0, 1, -15.6154, 42.7692);">
          <use href="#spring" class="pink">
          </use>
      </svg>
      <svg width="22px" height="22px" class="layer2 p8" style="transform: matrix(1, 0, 0, 1, -15.6154, 42.7692);">
          <use href="#line" class="aqua">
          </use>
      </svg>
      <svg width="22px" height="22px" class="layer1 p9" style="transform: matrix(0.42262, 0.90631, -0.90631, 0.42262, 15.6154, -42.7692);">
          <use href="#line" class="aqua">
          </use>
      </svg>
      <svg width="22px" height="22px" class="layer2 p10" style="transform: matrix(0.08716, 0.9962, -0.9962, 0.08716, -15.6154, 42.7692);">
          <use href="#line" class="yellow">
          </use>
      </svg>
      <svg width="28px" height="28px" class="layer1 p11" style="transform: matrix(-1, 0, 0, -1, 15.6154, -42.7692);">
          <use href="#semiCircle" class="aqua">
          </use>
      </svg>
      </div>*/}
    </ImgContainer>
  )
}

export default Shapes


let colorName;
let color;
let themeColor;
let primaryColor;
let secondaryColor;
let r1;
let g1;
let b1;
let r2;
let g2;
let b2;

const getRandomColor = () => {
  const colors = ['çyan', 'violet', 'orange', 'green', 'pink']
  colorName = colors[Math.floor(Math.random()*colors.length)];
};

window.onload = getRandomColor();

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

const countRGB = (primCol) => {
  const rgb = hexToRgb(primCol)
  r1 = rgb.r - 30;
  g1 = rgb.g - 30;
  b1 = rgb.b - 30;
  r2 = rgb.r + 30;
  g2 = rgb.g + 30;
  b2 = rgb.b + 30;
};

switch(colorName) {
  case 'cyan':
    color = 'cyan';
    primaryColor = '#69c9d0';     //cyan
    secondaryColor = '#ee1d52';   //red
    countRGB(primaryColor);
    break;
  case 'violet':
    color = 'violet';
    primaryColor = '#828CC8';     //violet
    secondaryColor = '#EEAF41';   //yellow
    countRGB(primaryColor);
    break;
  case 'orange':
    color = 'orange';
    primaryColor = '#E69B6F';     //orange
    secondaryColor = '#69c9d0';   //cyan
    countRGB(primaryColor);
    break;
  case 'green':
    color = 'green';
    primaryColor = '#91C372';     //green
    secondaryColor = '#CF4981';   //magenta
    countRGB(primaryColor);
    break;
  case 'pink':
    color = 'pink';
    primaryColor = '#f9cdd0';     //pink
    secondaryColor = '#828CC8';   //violet
    countRGB(primaryColor);
    break;
  default:
    color = 'cyan';
    primaryColor = '#69c9d0';
    secondaryColor = '#ee1d52';
    countRGB(primaryColor);
};

const getColorName = (img) => {
  const colorName = img.split('-')[1].split('.')[0];
  if (colorName === color) {
    return img;
  }; 
};

const getComponentColor = (colorArray) => {
  return colorArray.map(getColorName).filter(( element ) => element !== undefined)[0];
};

themeColor = {
  color,
  primaryColor,
  secondaryColor,
  r1,
  g1,
  b1,
  r2,
  g2,
  b2
};

export {
  themeColor,
  getComponentColor
};

import React from 'react'
import SkillsColumn from './SkillsColumn';
import {
  SkillsContainer,
  SkillsWrapper,
} from './SkillsElements';
import { skillObjOne, skillObjTwo } from './Data';
import { countNavbarHeight } from '../../CountDimensions';

const Skills = ({
  id,
  hardSideToggle,
  softSideToggle,
  tColor
}) => {
  const screenHeight = countNavbarHeight();
  return (
    <SkillsContainer id="skills"  screenHeight={screenHeight} tColor={tColor}>
      <SkillsWrapper id={id}>
        <SkillsColumn {...skillObjOne} sideToggle={hardSideToggle} tColor={tColor} />
        <SkillsColumn {...skillObjTwo} sideToggle={softSideToggle} tColor={tColor} />
      </SkillsWrapper>
    </SkillsContainer>
  )
}

export default Skills

import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import ContactMePage from './pages/contactme';
import StoryPage from './pages/story';
import EducationPage from './pages/education';
import ExperienceOnePage from './pages/experienceaccenture';
import ExperienceTwoPage from './pages/experiencekfbcontrol';
import ExperienceThreePage from './pages/experiencegadrilling';
import ExperienceFourPage from './pages/experiencemts';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contactme' element={<ContactMePage />} />
        <Route path='/story' element={<StoryPage />} />
        <Route path='/education' element={<EducationPage />} />
        <Route path='/experience/accenture' element={<ExperienceOnePage />} />
        <Route path='/experience/kfb-control' element={<ExperienceTwoPage />} />
        <Route path='/experience/ga-drilling' element={<ExperienceThreePage />} />
        <Route path='/experience/mts' element={<ExperienceFourPage />} />
      </Routes>
    </Router>
  );
};

export default App;


import { Dimensions } from 'react-native';

export const countHeight = () => {
    return `${Math.round(Dimensions.get('window').height)}px`;
};

export const countNavbarHeight = () => {
    return `${Math.round(Dimensions.get('window').height)-80}px`;
};

export const countWidth = () => {
    return `${Math.round(Dimensions.get('window').width)}px`;
};
import React from 'react'
import { ButtonSkills } from '../../ButtonElements';
import {
  Column,
  Row1,
  Row2,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img
} from './SkillsElements';

const SkillsColumn = ({
  sideToggle,
  id,
  topLine,
  headLine,
  description,
  buttonLabel,
  imgUp,
  img,
  alt,
  tColor
}) => {
  return (
    <Column id={id} imgUp={imgUp}>
      <Row1>
        <TextWrapper>
          <Heading>{headLine}</Heading>
          <Subtitle>{description}</Subtitle>
          <BtnWrap onClick={sideToggle}>
            <ButtonSkills
              smooth={true}
              duration={500}
              spy={true}
              exact='true'
              offset={-80}
              tColor={tColor}
            >{buttonLabel}</ButtonSkills>
          </BtnWrap>
        </TextWrapper>
      </Row1>
      <Row2>
        <ImgWrap>
          <Img src={img} alt={alt} />
        </ImgWrap>
      </Row2>
    </Column>
  );
};

export default SkillsColumn;

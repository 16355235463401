import React from 'react'
import {
  SoftContainer,
  Icon,
  CloseIcon,
  SkillsH1,
  SkillsWrapper,
  SkillsCategoryWrapper,
  SkillsItemsContainer,
  SkillsItemsWrapper,
  SkillsItems,
  SkillsTitle,
  SkillContainer,
  SkillWrapper,
  SkillName,
  SkillExperience
} from '../SkillElements';

const SoftSide = ({
  isSoftSideOpen, 
  softSideToggle,
  tColor
}) => {
  return (
    <SoftContainer isSoftSideOpen={isSoftSideOpen} onClick={softSideToggle}>
      <Icon>
        <CloseIcon tColor={tColor} />
      </Icon>
      <SkillsH1>SOFT SKILLS</SkillsH1>
      <SkillsWrapper>
        <SkillsCategoryWrapper>
          <SkillsItemsContainer>
            <SkillsItemsWrapper>
              <SkillsItems>
                <SkillsTitle>Languages</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Slovak</SkillName>
                    <SkillName>English</SkillName>
                    <SkillName>German</SkillName>
                    <SkillName>Italian</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <SkillExperience>🇸🇰🇸🇰🇸🇰🇸🇰🇸🇰</SkillExperience>
                    <SkillExperience>🇺🇸🇺🇸🇺🇸🇺🇸</SkillExperience>
                    <SkillExperience>🇩🇪🇩🇪</SkillExperience>
                    <SkillExperience>🇮🇹</SkillExperience>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
              <SkillsItems>
                <SkillsTitle>Strengths</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Problem Analyzing</SkillName>
                    <SkillName>Problem Solving</SkillName>
                    <SkillName>Endurance</SkillName>
                    <SkillName>Team Player</SkillName>
                    <SkillName>Manual Skills</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <SkillExperience>🤔🤔🤔</SkillExperience>
                    <SkillExperience>🧠🧠</SkillExperience>
                    <SkillExperience>🏃🏃🏃🏃</SkillExperience>
                    <SkillExperience>🤝🤝🤝🤝🤝</SkillExperience>
                    <SkillExperience>🛠️🛠️🛠️</SkillExperience>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
            </SkillsItemsWrapper>
            <SkillsItemsWrapper>
              <SkillsItems>
                <SkillsTitle>Hobbies</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Music & Art</SkillName>
                    <SkillName>Sports</SkillName>
                    <SkillName>Travelling</SkillName>
                    <SkillName>Partying</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <SkillExperience>🎸</SkillExperience>
                    <SkillExperience>🏋️</SkillExperience>
                    <SkillExperience>✈️</SkillExperience>
                    <SkillExperience>🥳</SkillExperience>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
              <SkillsItems>
                <SkillsTitle>Interests</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Volunteering</SkillName>
                    <SkillName>Languages</SkillName>
                    <SkillName>Sustainability</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <SkillExperience>🙋‍♂️</SkillExperience>
                    <SkillExperience>🌏</SkillExperience>
                    <SkillExperience>☘️</SkillExperience>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
            </SkillsItemsWrapper>
          </SkillsItemsContainer>
        </SkillsCategoryWrapper>
      </SkillsWrapper>
    </SoftContainer>
  )
}

export default SoftSide

import hardCyan from '../../../images/Skills/hard-cyan.svg';
import hardGreen from '../../../images/Skills/hard-green.svg';
import hardOrange from '../../../images/Skills/hard-orange.svg';
import hardPink from '../../../images/Skills/hard-pink.svg';
import hardViolet from '../../../images/Skills/hard-violet.svg';
import ssoftCyan from '../../../images/Skills/soft-cyan.svg';
import ssoftGreen from '../../../images/Skills/soft-green.svg';
import ssoftOrange from '../../../images/Skills/soft-orange.svg';
import ssoftPink from '../../../images/Skills/soft-pink.svg';
import ssoftViolet from '../../../images/Skills/soft-violet.svg';
import { getComponentColor } from '../../SetColor';

const hardColors = [hardCyan, hardGreen, hardOrange, hardPink, hardViolet]
const ssoftColors = [ssoftCyan, ssoftGreen, ssoftOrange, ssoftPink, ssoftViolet]

export const skillObjOne = {
  id: 'hard',
  headLine: 'HARD SKILLS',
  description: 'I work on these daily ...',
  buttonLabel: 'Discover',
  imgUp: true,
  img: getComponentColor(hardColors),
  alt: 'Campsite'
};

export const skillObjTwo = {
  id: 'soft',
  headLine: 'SOFT SKILLS',
  description: 'Proud of having so many ...',
  buttonLabel: 'Discover',
  imgUp: false,
  img: getComponentColor(ssoftColors),
  alt: 'Books'
};
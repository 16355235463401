import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const HeroContainer = styled.div`
  background: #1c1d24;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ screenHeight }) => (screenHeight)};
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:
      linear-gradient(
        180deg,
        rgba(0,0,0,0.2) 0%,
        transparent 30%
      ),
      linear-gradient(
        180deg,
        rgba(0,0,0,0.4) 0%,
        transparent 20%
      ),
      linear-gradient(
        180deg,
        transparent 80%,
        rgba(0,0,0,0.4) 100%
      );
    z-index: 2;
  };
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 800px) {
    font-size: 36px;
  };

  @media screen and (max-width: 480px) {
    font-size: 32px;
  };
`;

export const HeroP = styled.p`
  margin-top: 50px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  max-width: 800px;

  @media screen and (max-width: 800px) {
    margin-top: 38px;
    font-size: 11px;
    max-width: 700px;
  };

  @media screen and (max-width: 480px) {
    margin-top: 30px;
    font-size: 10px;
    max-width: 400px;
  };
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

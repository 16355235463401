import styled from "styled-components";
import { FaTimes } from 'react-icons/fa';

export const HardContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  color: #fff;
  transition: 0.3s ease-in-out;
  opacity: ${({ isHardSideOpen }) => (isHardSideOpen ? '95%' : '100%')};
  left: ${({ isHardSideOpen }) => (isHardSideOpen ? '0' : '-100%')};
`;

export const SoftContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  color: #fff;
  transition: 0.3s ease-in-out;
  opacity: ${({ isSoftSideOpen }) => (isSoftSideOpen ? '95%' : '0')};
  right: ${({ isSoftSideOpen }) => (isSoftSideOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
  color: ${({ tColor }) => (tColor.primaryColor)};
  
  &:hover {
    color: ${({ tColor }) => (tColor.secondaryColor)};
    transition: 0.2s ease-in-out;
  };
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.3rem;
  right: 1.8rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SkillsH1 = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 64px;

  @media screen and (max-width: 1300px) {
    font-size: 2rem;
    margin-bottom: 40px;
  };
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  };
`;

export const SkillsWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-left: 100px;

  @media screen and (max-width: 1050px) {
    padding-left: 50px;
  };
  @media screen and (max-width: 600px) {
    padding-left: 10px;
  };
`;

export const SkillsCategoryWrapper = styled.div`
  max-width: 1100px;
  display: flex;
  flex-direction: column;
`;

export const SkillsItemsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media screen and (max-width: 1050px) {
    grid-gap: 30px;
  };
  @media screen and (max-width: 600px) {
    flex-direction: column;
    grid-gap: 10px;
  };
`;

export const SkillsItemsWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const SkillsItems = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  @media screen and (max-width: 1050px) {
    margin-bottom: 10px;
  };
  /* align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 220px;
  box-sizing: border-box; */
`;

export const SkillsTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;

  @media screen and (max-width: 1300px) {
    font-size: 1.2rem;
    margin-bottom: 15px;
  };
  @media screen and (max-width: 600px) {
    font-size: 1rem;
    margin-bottom: 2px;
  };
`;

export const SkillContainer = styled.div`
  max-width: 500px;
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
`;

export const SkillWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkillName = styled.p`
  width: 250px;
  padding-top: 3px;
  height: 30px;
  font-size: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 1300px) {
    height: 25px;
    font-size: 15px;
    margin-bottom: 6px;
  };
  @media screen and (max-width: 1050px) {
    width: 150px;
    height: 20px;
    font-size: 10px;
    margin-bottom: 4px;
  };
  @media screen and (max-width: 600px) {
    width: 150px;
    height: 20px;
    font-size: 12px;
    margin-bottom: 4px;
  };
  @media screen and (max-width: 600px) {
    width: 130px;
    height: 15px;
    font-size: 10px;
    margin-bottom: 2px;
  };
`;

export const SkillExperience = styled.p`
  width: 200px;
  height: 30px;
  font-size: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 1300px) {
    height: 25px;
    font-size: 15px;
    margin-bottom: 6px;
  };
  @media screen and (max-width: 1050px) {
    width: 100px;
    height: 20px;
    font-size: 12px;
    margin-bottom: 4px;
  };
  @media screen and (max-width: 600px) {
    width: 70px;
    height: 15px;
    font-size: 10px;
    margin-bottom: 2px;
  };
`;

export const ImgWrap = styled.div`
  width: 200px;
  height: 100%;

  @media screen and (max-width: 1050px) {
    width: 100px;
  };
  @media screen and (max-width: 600px) {
    width: 70px;
    margin-bottom: -3px;
  };
`;

export const Img = styled.img`
  height: 30px;
  padding-right: 3px;

  @media screen and (max-width: 1300px) {
    height: 25px;
    padding-right: 2px;
  };
  @media screen and (max-width: 1050px) {
    height: 20px;
    padding-right: 1px;
  };
  @media screen and (max-width: 600px) {
    height: 15px;
    padding-right: 0px;
  };
`;
import React from 'react';
import accentureOrange from '../../../images/Experience/accenture-orange.svg';
import accentureCyan from '../../../images/Experience/accenture-cyan.svg';
import accentureGreen from '../../../images/Experience/accenture-green.svg';
import accenturePink from '../../../images/Experience/accenture-pink.svg';
import accentureViolet from '../../../images/Experience/accenture-violet.svg';
import gadrillingCyan from '../../../images/Experience/gadrilling-cyan.svg';
import gadrillingGreen from '../../../images/Experience/gadrilling-green.svg';
import gadrillingOrange from '../../../images/Experience/gadrilling-orange.svg';
import gadrillingPink from '../../../images/Experience/gadrilling-pink.svg';
import gadrillingViolet from '../../../images/Experience/gadrilling-violet.svg';
import kfbcontrolCyan from '../../../images/Experience/kfbcontrol-cyan.svg';
import kfbcontrolGreen from '../../../images/Experience/kfbcontrol-green.svg';
import kfbcontrolOrange from '../../../images/Experience/kfbcontrol-orange.svg';
import kfbcontrolPink from '../../../images/Experience/kfbcontrol-pink.svg';
import kfbcontrolViolet from '../../../images/Experience/kfbcontrol-violet.svg';
import mtsCyan from '../../../images/Experience/mts-cyan.svg';
import mtsGreen from '../../../images/Experience/mts-green.svg';
import mtsOrange from '../../../images/Experience/mts-orange.svg';
import mtsPink from '../../../images/Experience/mts-pink.svg';
import mtsViolet from '../../../images/Experience/mts-violet.svg';
import {
  ExperienceContainer,
  ExperienceH1,
  ExperienceWrapper,
  ExperienceCard,
  ExperienceIcon,
  ExperienceH2,
  ExperienceP
} from './ExperienceElements';
import { getComponentColor } from '../../SetColor';
import { countNavbarHeight } from '../../CountDimensions';

const Experience = ({
  tColor
}) => {
  const screenHeight = countNavbarHeight();
  
  const accentureColors = [accentureCyan, accentureGreen, accentureOrange, accenturePink, accentureViolet];
  const gadrillingColors = [gadrillingCyan, gadrillingGreen, gadrillingOrange, gadrillingPink, gadrillingViolet];
  const kfbcontrolColors = [kfbcontrolCyan, kfbcontrolGreen, kfbcontrolOrange, kfbcontrolPink, kfbcontrolViolet];
  const mtsColors = [mtsCyan, mtsGreen, mtsOrange, mtsPink, mtsViolet];

  return (
    <ExperienceContainer id="experience" screenHeight={screenHeight}>
      <ExperienceH1>MY EXPERIENCE</ExperienceH1>
      <ExperienceWrapper>
        <ExperienceCard to='/experience/accenture'>
          <ExperienceIcon src={getComponentColor(accentureColors)} />
          <ExperienceH2>Software Developer</ExperienceH2>
          <ExperienceP>Accenture</ExperienceP>
        </ExperienceCard>
        <ExperienceCard to='/experience/kfb-control'>
          <ExperienceIcon src={getComponentColor(kfbcontrolColors)} />
          <ExperienceH2>Application Engineer</ExperienceH2>
          <ExperienceP>KFB Control</ExperienceP>
        </ExperienceCard>
        <ExperienceCard to='/experience/ga-drilling'>
          <ExperienceIcon src={getComponentColor(gadrillingColors)} />
          <ExperienceH2>Mechatronics Engineer</ExperienceH2>
          <ExperienceP>GA Drilling</ExperienceP>
        </ExperienceCard>
        <ExperienceCard to='/experience/mts'>
          <ExperienceIcon src={getComponentColor(mtsColors)} />
          <ExperienceH2>PLC Programmer</ExperienceH2>
          <ExperienceP>MTS</ExperienceP>
        </ExperienceCard>
      </ExperienceWrapper>
    </ExperienceContainer>
  );
};

export default Experience;

import story from '../../images/InfoSection/mystory-500x500.jpg';
import education from '../../images/InfoSection/education-500x500.jpg';
import accenture from '../../images/Experience/accenture-500x500.jpg';
import kfbcontrol from '../../images/Experience/kfbcontrol-500x500.png';
import gadrilling from '../../images/Experience/gadrilling-500x500.jpg';
import mts from '../../images/Experience/mts-500x500.jpg';

export const storyObj = {
  id: 'storyPage',
  lightBg: false,
  lightText: true,
  headLine: 'It all started before I could count to three',
  description1: 'As a kid I had big dreams, I wanted to be an astronaut, to own a Ferrari and much more. ' +
               'Simply, I always wanted to do something special. ' +
               'And that\'s how I ended up in one of the greatest software industry companies '+
               'working on Image Recognition applications.',
  timePeriod1: '',
  description2: '',
  timePeriod2: '',
  description3: '',
  data1: '',
  data2: '',
  data3: '',
  data4: '',
  data5: '',
  imgStart: true,
  img: story,
  alt: 'Myself',
  darkText: false
};

export const educationObj = {
  id: 'educationPage',
  lightBg: false,
  lightText: true,
  headLine: 'Slovak University of Technology in Bratislava',
  description1: 'Applied Mechanics and Mechatronics',
  timePeriod1: '2017 - 2019',
  description2: 'Master\'s degree',
  timePeriod2: '2014 - 2017',
  description3: 'Bachelor\'s degree',
  data1: '',
  data2: '',
  data3: '',
  data4: '',
  data5: '',
  imgStart: false,
  img: education,
  alt: 'Graduation',
  darkText: false
};

export const experienceObjOne = {
  id: 'experienceOnePage',
  lightBg: true,
  lightText: false,
  headLine: 'Software Developer',
  description1: 'Functional Programming, DevOps & Serverless application development',
  timePeriod1: 'Jan 2021 - Present',
  description2: '',
  timePeriod2: '',
  description3: '',
  data1: 'Programming - Node.js, Python, React, SQL',
  data2: 'AWS Cloud - Lambda, API Gateway, S3',
  data3: 'Databases - AWS RDS, Amazon Aurora',
  data4: 'DevOps - Terraform, Git, AWS CDK',
  data5: 'Scrum & Agile - Azure DevOps',
  imgStart: true,
  img: accenture,
  alt: 'Accenture',
  darkText: true
};

export const experienceObjTwo = {
  id: 'experienceTwoPage',
  lightBg: true,
  lightText: false,
  headLine: 'Application Engineer',
  description1: 'DCS Systems, Electronics, Networking and many business trips',
  timePeriod1: 'Sep 2019 - Dec 2020',
  description2: '',
  timePeriod2: '',
  description3: '',
  data1: 'Boehringer Ingelheim Wien - Honeywell',
  data2: 'DUSLO Šaľa - PI System',
  data3: 'CHEMES Humenné - Honeywell',
  data4: 'INEOS Köln - Foxboro',
  data5: '',
  imgStart: true,
  img: kfbcontrol,
  alt: 'KFB Control',
  darkText: true
};

export const experienceObjThree = {
  id: 'experienceThreePage',
  lightBg: true,
  lightText: false,
  headLine: 'Mechatronics Engineer',
  description1: 'Procedural Programming, Electronics, Mechanics and Master\'s Thesis',
  timePeriod1: 'Jan 2018 - Jun 2019',
  description2: '',
  timePeriod2: '',
  description3: '',
  data1: 'Programming - Arduino, C',
  data2: 'Electronics - Tina',
  data3: 'Mechanics - DS Catia',
  data4: '',
  data5: '',
  imgStart: true,
  img: gadrilling,
  alt: 'GA Drilling',
  darkText: true
};

export const experienceObjFour = {
  id: 'experienceFourPage',
  lightBg: true,
  lightText: false,
  headLine: 'PLC Programmer',
  description1: 'Object Oriented Programming, PLC Systems & Robotics',
  timePeriod1: 'Jun 2017 - Sept 2017',
  description2: '',
  timePeriod2: '',
  description3: '',
  data1: 'Programming - C#',
  data2: 'Automation - TIA Portal, TwinCAT',
  data3: '',
  data4: '',
  data5: '',
  imgStart: true,
  img: mts,
  alt: 'MTS',
  darkText: true
};
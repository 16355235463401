import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import OtherPages from '../components/OtherPages';
import { educationObj } from '../components/OtherPages/Data';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { themeColor } from '../components/SetColor';
import Loading from '../components/OtherPages/Loading';
import { SetLoading } from '../components/SetLoading';

const EducationPage = () => {
  const loading = SetLoading(500);
  return (
    <>
      { loading ? (
        <Loading tColor={themeColor} loading={loading}/>
      ) : (
        <HelmetProvider>
          <Helmet><title>mm-code | Education</title></Helmet>
          <ScrollToTop />
          <OtherPages {...educationObj} tColor={themeColor} />
        </HelmetProvider>
      )};
    </>
  );
};

export default EducationPage;
import { animateScroll as scroll } from 'react-scroll';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin
} from 'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  LinkContainer,
  Logo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink
} from './FooterElements';
import logoCyan from '../../../images/Logo/mmcode-cyan.png';
import logoGreen from '../../../images/Logo/mmcode-green.png';
import logoOrange from '../../../images/Logo/mmcode-orange.png';
import logoPink from '../../../images/Logo/mmcode-pink.png';
import logoViolet from '../../../images/Logo/mmcode-violet.png';
import { getComponentColor } from '../../SetColor';

const toggleHome = () => {
  scroll.scrollToTop();
};

const Footer = ({
  tColor
}) => {
  const logoColors = [logoCyan, logoGreen, logoOrange, logoPink, logoViolet];

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Education</FooterLinkTitle>
              <FooterLink href='https://gsa.edupage.org/' target='_blank' tColor={tColor}>St. Andrew High School</FooterLink>
              <FooterLink href='https://www.stuba.sk/' target='_blank' tColor={tColor}>Slovak university of technology</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Experience</FooterLinkTitle>
              <FooterLink href='https://www.accenture.com/' target='_blank' tColor={tColor}>Accenture</FooterLink>
              <FooterLink href='https://www.kfb.sk/' target='_blank' tColor={tColor}>KFB Control</FooterLink>
              <FooterLink href='https://www.gadrilling.com/' target='_blank' tColor={tColor}>GA Drilling</FooterLink>
              <FooterLink href='https://www.mts.sk/' target='_blank' tColor={tColor}>MTS</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <LinkContainer to='/' onClick={toggleHome}>
              <Logo src={getComponentColor(logoColors)} />
            </LinkContainer>
            <WebsiteRights>
              mm-code © {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink href='https://www.facebook.com/marek.majkut.54' target='_blank' aria-label='Facebook' tColor={tColor}>
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href='https://www.instagram.com/mano_majkut/' target='_blank' aria-label='Instagram' tColor={tColor}>
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href='https://www.linkedin.com/in/marek-majk%C3%BAt-7a308a18b/' target='_blank' aria-label='Linkedin' tColor={tColor}>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;

import node from '../../../images/Skills/node.svg';
import javascript from '../../../images/Skills/javascript.svg';
import python from '../../../images/Skills/python.svg';
import react from '../../../images/Skills/react.svg';
import java from '../../../images/Skills/java.svg';
import sql from '../../../images/Skills/sql.svg';
import cloud from '../../../images/Skills/cloud.svg';
import ado from '../../../images/Skills/azure.svg';
import terraform from '../../../images/Skills/terraform.svg';
import git from '../../../images/Skills/git.svg';
import bash from '../../../images/Skills/bash.svg';
import aws from '../../../images/Skills/aws.svg';
import postgresql from '../../../images/Skills/postgresql.svg';

import React from 'react'
import {
  HardContainer,
  Icon,
  CloseIcon,
  SkillsH1,
  SkillsWrapper,
  SkillsCategoryWrapper,
  SkillsItemsContainer,
  SkillsItemsWrapper,
  SkillsItems,
  SkillsTitle,
  SkillContainer,
  SkillWrapper,
  SkillName,
  ImgWrap,
  Img
} from '../SkillElements';

const HardSide = ({
  isHardSideOpen,
  hardSideToggle,
  tColor
}) => {
  return (
    <HardContainer isHardSideOpen={isHardSideOpen} onClick={hardSideToggle}>
      <Icon>
        <CloseIcon tColor={tColor} />
      </Icon>
      <SkillsH1>HARD SKILLS</SkillsH1>
      <SkillsWrapper>
        <SkillsCategoryWrapper>
          <SkillsItemsContainer>
            <SkillsItemsWrapper>
              <SkillsItems>
                <SkillsTitle>Languages</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Node.js</SkillName>
                    <SkillName>Java Script</SkillName>
                    <SkillName>Python</SkillName>
                    <SkillName>React</SkillName>
                    <SkillName>Java</SkillName>
                    <SkillName>SQL</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <ImgWrap>
                      <Img src={node} alt='Node-1' />
                      <Img src={node} alt='Node-2' />
                      <Img src={node} alt='Node-3' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={javascript} alt='Javascript-1' />
                      <Img src={javascript} alt='Javascript-2' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={python} alt='Python-1' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={react} alt='React-1' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={java} alt='Java-1' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={sql} alt='SQL-1' />
                      <Img src={sql} alt='SQL-2' />
                    </ImgWrap>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
              <SkillsItems>
                <SkillsTitle>Certifications</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>AWS SAA</SkillName>
                    <SkillName>AWS Cloud Practitioner</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <ImgWrap>
                      <Img src={cloud} alt='SAA-1' />
                      <Img src={cloud} alt='SAA-2' />
                      <Img src={cloud} alt='SAA-3' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={cloud} alt='ACP-1' />
                      <Img src={cloud} alt='ACP-2' />
                    </ImgWrap>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
            </SkillsItemsWrapper>
            <SkillsItemsWrapper>
              <SkillsItems>
                <SkillsTitle>DevOps</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>Azure DevOps</SkillName>
                    <SkillName>Terraform</SkillName>
                    <SkillName>Git</SkillName>
                    <SkillName>Bash</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <ImgWrap>
                      <Img src={ado} alt='ADO-1' />
                      <Img src={ado} alt='ADO-2' />
                      <Img src={ado} alt='ADO-3' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={terraform} alt='Terraform-1' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={git} alt='Git-1' />
                      <Img src={git} alt='Git-2' />
                    </ImgWrap>
                    <ImgWrap>
                      <Img src={bash} alt='Bash-1' />
                    </ImgWrap>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
              <SkillsItems>
                <SkillsTitle>Cloud</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>AWS</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <ImgWrap>
                      <Img src={aws} alt='AWS-1' />
                      <Img src={aws} alt='AWS-2' />
                      <Img src={aws} alt='AWS-3' />
                    </ImgWrap>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
              <SkillsItems>
                <SkillsTitle>Database</SkillsTitle>
                <SkillContainer>
                  <SkillWrapper>
                    <SkillName>PostgreSQL</SkillName>
                  </SkillWrapper>
                  <SkillWrapper>
                    <ImgWrap>
                      <Img src={postgresql} alt='PostgreSQL-1' />
                      <Img src={postgresql} alt='PostgreSQL-2' />
                    </ImgWrap>
                  </SkillWrapper>
                </SkillContainer>
              </SkillsItems>
            </SkillsItemsWrapper>
          </SkillsItemsContainer>
        </SkillsCategoryWrapper>
      </SkillsWrapper>
    </HardContainer>
  )
}

export default HardSide

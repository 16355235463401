import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
  Nav,
  NavbarContainer,
  LinkContainer,
  Logo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  BarsIcon
} from './NavbarElements';
import logoCyan from '../../../images/Logo/mmcode-cyan.png';
import logoGreen from '../../../images/Logo/mmcode-green.png';
import logoOrange from '../../../images/Logo/mmcode-orange.png';
import logoPink from '../../../images/Logo/mmcode-pink.png';
import logoViolet from '../../../images/Logo/mmcode-violet.png';
import { getComponentColor } from '../../SetColor';

const Navbar = ({
  sidebarToggle,
  tColor
}) => {
  const [scrollNav, setScrollNav] = useState(false);
  const logoColors = [logoCyan, logoGreen, logoOrange, logoPink, logoViolet];

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <LinkContainer to='/' onClick={toggleHome}>
              <Logo src={getComponentColor(logoColors)} />
            </LinkContainer>
            <MobileIcon onClick={sidebarToggle}>
              <BarsIcon tColor={tColor} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to='story'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  tColor={tColor}
                >Story
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='education'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  tColor={tColor}
                >Education
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='experience'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  tColor={tColor}
                >Experience
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='skills'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  tColor={tColor}
                >Skills
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to='explore'
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact='true'
                  offset={-80}
                  tColor={tColor}
                >Explore
                </NavLinks>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink to='/contactme' tColor={tColor} >Contact Me</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;

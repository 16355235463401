import React, { useState } from 'react';
import { ButtonScroll } from '../../ButtonElements';
import { countHeight } from '../../CountDimensions';
import Shapes from './Shapes';
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

const HeroSection = ({
  tColor
}) => {
  const screenHeight = countHeight();
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id='home' screenHeight={screenHeight}>
      <HeroBg>
        <Shapes/>
      </HeroBg>
      <HeroContent>
        <HeroH1>Hi I'm Marek,</HeroH1>
        <HeroH1>a Software Developer.</HeroH1>
        <HeroP>Back End Developer / Image Recognition Enthusiast / Serverless  Architect</HeroP>
        <HeroBtnWrapper>
          <ButtonScroll
            to='story'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            dark='true'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            tColor={tColor}>Explore {hover ? <ArrowForward /> : <ArrowRight />}
          </ButtonScroll>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

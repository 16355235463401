import educationCyan from '../../../images/InfoSection/education-cyan.svg';
import educationGreen from '../../../images/InfoSection/education-green.svg';
import educationOrange from '../../../images/InfoSection/education-orange.svg';
import educationPink from '../../../images/InfoSection/education-pink.svg';
import educationViolet from '../../../images/InfoSection/education-violet.svg';
import exploreCyan from '../../../images/InfoSection/explore-cyan.svg';
import exploreGreen from '../../../images/InfoSection/explore-green.svg';
import exploreOrange from '../../../images/InfoSection/explore-orange.svg';
import explorePink from '../../../images/InfoSection/explore-pink.svg';
import exploreViolet from '../../../images/InfoSection/explore-violet.svg';
import storyCyan from '../../../images/InfoSection/story-cyan.svg';
import storyGreen from '../../../images/InfoSection/story-green.svg';
import storyOrange from '../../../images/InfoSection/story-orange.svg';
import storyPink from '../../../images/InfoSection/story-pink.svg';
import storyViolet from '../../../images/InfoSection/story-violet.svg';
import { getComponentColor } from '../../SetColor';


const educationColors = [educationCyan, educationGreen, educationOrange, educationPink, educationViolet]
const exploreColors = [exploreCyan, exploreGreen, exploreOrange, explorePink, exploreViolet]
const storyColors = [storyCyan, storyGreen, storyOrange, storyPink, storyViolet]

export const homeObjOne = {
  id: 'story',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'My Story',
  headLine: '"Every great story starts with a dream, but it\'s you who can turn it into reality."',
  description: 'It all started before I could count to three. As a kid I had big dreams...',
  buttonLabel: 'Read More',
  imgStart: false,
  img: getComponentColor(storyColors),
  alt: 'Campsite',
  dark: true,
  primary: true,
  darkText: false,
  router: 'story',
};

export const homeObjTwo = {
  id: 'education',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Education',
  headLine: '"What saves a man is to take a step. Then another step. It is always the same step, but you have to take it."',
  description: 'They say one should not stand out from the crowd. I was the exact opposite...',
  buttonLabel: 'Read More',
  imgStart: true,
  img: getComponentColor(educationColors),
  alt: 'Books',
  dark: false,
  primary: false,
  darkText: true,
  router: 'education',
};

export const homeObjThree = {
  id: 'explore',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Stay in touch',
  headLine: 'Did my portfolio caught your attention?',
  description: 'If you like my portfolio and want to know more, feel free to contact me. Let\'s stay in touch.',
  buttonLabel: 'Contact Me',
  imgStart: false,
  img: getComponentColor(exploreColors),
  alt: 'Journey',
  dark: false,
  primary: false,
  darkText: true,
  router: 'contactme',
};

import { useState, useEffect } from "react";

export const SetLoading = (time) => {
  const [loading, setLoading] = useState(false);

  useEffect(
    () => {
      setLoading(true);
      setTimeout(() => {
          setLoading(false)
      }, time)
    }, [time]
  );

  return loading;
};
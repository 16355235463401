import React, { useState } from 'react';
import Sidebar from '../components/SidePages/Sidebar';
import Navbar from '../components/PrimaryPage/Navbar';
import HeroSection from '../components/PrimaryPage/HeroSection';
import InfoSection from '../components/PrimaryPage/InfoSection';
import Experience from '../components/PrimaryPage/Experience';
import Skills from '../components/PrimaryPage/Skills';
import HardSide from '../components/SidePages/HardSide';
import SoftSide from '../components/SidePages/SoftSide';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/PrimaryPage/InfoSection/Data';
import Footer from '../components/PrimaryPage/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { themeColor } from '../components/SetColor';
import Loading from '../components/OtherPages/Loading';
import { SetLoading } from '../components/SetLoading';
const Home = () => {
  const loading = SetLoading(300);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHardSideOpen, setIsHardSideOpen] = useState(false);
  const [isSoftSideOpen, setIsSoftSideOpen] = useState(false);

  const sidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const hardSideToggle = () => {
    setIsHardSideOpen(!isHardSideOpen);
  };
  const softSideToggle = () => {
    setIsSoftSideOpen(!isSoftSideOpen);
  };
  return (
    <>
      { loading ? (
        <Loading tColor={themeColor} loading={loading}/>
      ) : (
        <HelmetProvider>
          <Helmet><title>mm-code | Home</title></Helmet>
          <Sidebar isSidebarOpen={isSidebarOpen} sidebarToggle={sidebarToggle} tColor={themeColor} />
          <HardSide isHardSideOpen={isHardSideOpen} hardSideToggle={hardSideToggle} tColor={themeColor} />
          <SoftSide isSoftSideOpen={isSoftSideOpen} softSideToggle={softSideToggle} tColor={themeColor} />
          <Navbar sidebarToggle={sidebarToggle} tColor={themeColor} />
          <HeroSection tColor={themeColor} />
          <InfoSection {...homeObjOne} tColor={themeColor} />
          <InfoSection {...homeObjTwo} tColor={themeColor} />
          <Experience tColor={themeColor} />
          <Skills hardSideToggle={hardSideToggle} softSideToggle={softSideToggle} tColor={themeColor} />
          <InfoSection {...homeObjThree} tColor={themeColor} />
          <Footer tColor={themeColor} />
        </HelmetProvider>
      )};
    </>
  );
};

export default Home;

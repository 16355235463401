import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute
} from './SidebarElements';

const Sidebar = ({
  isSidebarOpen,
  sidebarToggle,
  tColor
}) => {
  return (
    <SidebarContainer isSidebarOpen={isSidebarOpen} onClick={sidebarToggle}>
      <Icon>
        <CloseIcon tColor={tColor} />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='story' offset={-80} onClick={sidebarToggle} tColor={tColor}>Story</SidebarLink>
          <SidebarLink to='education' offset={-80} onClick={sidebarToggle} tColor={tColor}>Education</SidebarLink>
          <SidebarLink to='experience' offset={-80} onClick={sidebarToggle} tColor={tColor}>Experience</SidebarLink>
          <SidebarLink to='skills' offset={-80} onClick={sidebarToggle} tColor={tColor}>Skills</SidebarLink>
          <SidebarLink to='explore' offset={-80} onClick={sidebarToggle} tColor={tColor}>Explore</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to='/contactme' onClick={sidebarToggle} tColor={tColor}>Contact Me</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;

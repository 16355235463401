import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: ${({ screenHeight }) => (screenHeight)};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(
    108deg,
    rgba(${({ tColor }) => (tColor.r1)}, ${({ tColor }) => (tColor.g1)}, ${({ tColor }) => (tColor.b1)}, 1) 0%,
    rgba(${({ tColor }) => (tColor.r2)}, ${({ tColor }) => (tColor.g2)}, ${({ tColor }) => (tColor.b2)}, 1) 100%
  );

  @media screen and (max-width: 480px) {
    position: relative;
  };
  @media screen and (max-height: 850px) {
    position: relative;
  };
`;

export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const LinkContainer = styled(Link)`
  cursor: pointer;
  width: 200px;
  height: 120px;
  margin-top: 5px;
`;

export const Logo = styled.img`
  height: 100px;
  width: 180px;
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.form`
  background: #010101;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 40px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
  justify-content: center;

  @media screen and (max-width: 480px) {
    max-width: 220px;
  };
  @media screen and (max-height: 850px) {
    max-width: 220px;
  };
`;

export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;

  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 16px;
  };
  @media screen and (max-height: 850px) {
    margin-bottom: 20px;
    font-size: 16px;
  };
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;

  @media screen and (max-width: 480px) {
    margin-bottom: 4px;
    font-size: 10px;
  };
  @media screen and (max-height: 850px) {
    margin-bottom: 4px;
    font-size: 10px;
  };
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom:32px;
  border: none;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 12px;
    width: 200px;
    height: 25px;
  };
  @media screen and (max-height: 850px) {
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 12px;
    width: 200px;
    height: 25px;
  };

  &:invalid {
    border: 2px solid ${({ tColor }) => (tColor.secondaryColor)};
  }
`;

export const FormText = styled.textarea`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  height: 100px;

  @media screen and (max-width: 480px) {
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 12px;
    width: 200px;
    height: 60px;
  };
  @media screen and (max-height: 850px) {
    padding: 10px 10px;
    margin-bottom: 15px;
    font-size: 12px;
    width: 200px;
    height: 60px;
  };

  &:invalid {
    border: 2px solid ${({ tColor }) => (tColor.secondaryColor)};
  }
`;

export const FormButton = styled.button`
  background: ${({ tColor }) => (tColor.primaryColor)};
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    padding: 1px 0;
    font-size: 14px;
    width: 200px;
    height: 25px;
  };
  @media screen and (max-height: 850px) {
    padding: 1px 0;
    font-size: 14px;
    width: 200px;
    height: 25px;
  };

  &:hover {
    background: ${({ tColor }) => (tColor.secondaryColor)};
    transition: 0.2s ease-in-out;
  };
`;

export const RecapchaWrapper = styled.div`
  padding: 0 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 0 8px;
    margin-bottom: 15px;
    width: 192px;

    border-right: 1px solid #d8d8d8;
    overflow: hidden;
  };
  @media screen and (max-height: 850px) {
    padding: 0 8px;
    margin-bottom: 15px;
    width: 192px;

    border-right: 1px solid #d8d8d8;
    overflow: hidden;
  };
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;

  @media screen and (max-width: 480px) {
    margin-top: 4px;
    font-size: 10px;
  };
  @media screen and (max-height: 850px) {
    margin-top: 4px;
    font-size: 10px;
  };
`;
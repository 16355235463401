import React from "react";
import { countHeight } from '../../CountDimensions';
import { ClipLoader } from 'react-spinners';
import {
  Container,
  Wrapper
} from './LoadingElements';

const Loading = ({
  tColor,
  load
}) => {
  const screenHeight = countHeight();

  return (
    <Container screenHeight={screenHeight} >
      <Wrapper>
        <ClipLoader color={tColor.primaryColor} loading={load} size={30} />
      </Wrapper>
    </Container>
  );
};

export default Loading;
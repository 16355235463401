import styled from "styled-components";

export const Container = styled.div`
  height: ${({ screenHeight }) => (screenHeight)};
  position: fixed;
  display: grid;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(108deg, rgba(0, 0, 0, 1) 0%, rgba(31, 36, 36, 1) 100%);
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Recaptcha from 'react-recaptcha';
import { countHeight } from '../../CountDimensions';
import {
  serviceId,
  templateId,
  userId,
  reCaptcha_siteKey
} from './credentials.json'
import {
  Container,
  FormWrap,
  LogoContainer,
  LogoWrap,
  LinkContainer,
  Logo,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormText,
  FormButton,
  RecapchaWrapper,
  Text
} from './ContactMeElements';
import logoWhite from '../../../images/Logo/mmcode-white.png';

const ContactMe = ({
  tColor
}) => {
  const screenHeight = countHeight();
  const form = useRef();
  const [isVerified, setIsVerified] = useState(false);
  const [recaptchaLoad, setRecaptchaLoad] = useState(false);

  const recaptchaLoaded = () => {
    setIsVerified(!recaptchaLoad);
  };
  const verifiedRecaptcha = (response) => {
    if (response) {
      setRecaptchaLoad(!isVerified);
    };
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (recaptchaLoad && isVerified) {
      emailjs.sendForm(serviceId, templateId, form.current, userId)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert('Your message has been sent successfully.');
      form.current.reset();
    } else {
      alert('Please check the Recaptcha before sending your message.');
    }
  };
  console.log('recaptchaLoad: ', recaptchaLoad)
  console.log('isVerified: ', isVerified)
  return (
    <>
      <Container screenHeight={screenHeight} tColor={tColor}>
        <FormWrap>
          <LogoContainer>
            <LogoWrap>
              <LinkContainer to='/'>
                <Logo src={logoWhite} />
              </LinkContainer>
            </LogoWrap>
          </LogoContainer>
          <FormContent>
            <Form ref={form} onSubmit={sendEmail}>
              <FormH1>Send me an Email</FormH1>
              <FormInput type='text' placeholder='Subject' name='subject' tColor={tColor} required />
              <FormInput type='text' placeholder='Name' name='name' tColor={tColor} />
              <FormInput type='email' placeholder='Email' name='email' tColor={tColor} required />
              <FormLabel htmlFor='for'>Message</FormLabel>
              <FormText type='text' placeholder='Your Message' name='message' tColor={tColor} required />
              <RecapchaWrapper>
                <Recaptcha
                  sitekey={reCaptcha_siteKey}
                  render="explicit"
                  onloadCallback={recaptchaLoaded}
                  verifyCallback={verifiedRecaptcha}
                />
              </RecapchaWrapper>
              <FormButton type='submit' tColor={tColor}>Send</FormButton>
              <Text>Thank you</Text>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default ContactMe;

import React from 'react';
import { countHeight } from '../CountDimensions';
import {
  OtherPagesContainer,
  LogoContainer,
  LogoWrap,
  LinkContainer,
  Logo,
  OtherPagesWrapper,
  OtherPagesRow,
  Column1,
  Column2,
  TextWrapper,
  Heading,
  Subtitle,
  TimePeriod,
  Data,
  ImgWrap,
  Img
} from './OtherPagesElements';
import logoCyan from '../../images/Logo/mmcode-cyan.png';
import logoGreen from '../../images/Logo/mmcode-green.png';
import logoOrange from '../../images/Logo/mmcode-orange.png';
import logoPink from '../../images/Logo/mmcode-pink.png';
import logoViolet from '../../images/Logo/mmcode-violet.png';
import { getComponentColor } from '../SetColor';

const OtherPages = ({
  lightBg,
  id,
  imgStart,
  lightText,
  headLine,
  darkText,
  description1,
  description2,
  description3,
  timePeriod1,
  timePeriod2,
  data1,
  data2,
  data3,
  data4,
  data5,
  img,
  alt,
  tColor
}) => {
  const screenHeight = countHeight();
  const logoColors = [logoCyan, logoGreen, logoOrange, logoPink, logoViolet];
  return (
    <>
      <OtherPagesContainer lightBg={lightBg} id={id} screenHeight={screenHeight} >
        <OtherPagesWrapper>
          <LogoContainer>
            <LogoWrap>
              <LinkContainer to='/'>
                <Logo src={getComponentColor(logoColors)} />
              </LinkContainer>
            </LogoWrap>
          </LogoContainer>
          <OtherPagesRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle darkText={darkText}>{description1}</Subtitle>
                <TimePeriod tColor={tColor}>{timePeriod1}</TimePeriod>
                <Subtitle darkText={darkText}>{description2}</Subtitle>
                <TimePeriod tColor={tColor}>{timePeriod2}</TimePeriod>
                <Subtitle darkText={darkText}>{description3}</Subtitle>
                <Data>{data1}</Data>
                <Data>{data2}</Data>
                <Data>{data3}</Data>
                <Data>{data4}</Data>
                <Data>{data5}</Data>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </OtherPagesRow>
        </OtherPagesWrapper>
      </OtherPagesContainer>
    </>
  );
};

export default OtherPages;
